import React, { useContext } from 'react'

import security from '../../../assets/benefits/security.png'
import handshake from '../../../assets/benefits/handshake.png'
import clock from '../../../assets/benefits/clock.png'
import dollar from '../../../assets/benefits/dollar.png'
import forward from '../../../assets/benefits/forward.png'

import LanguageContext from '../../../context/language/LanguageContext'

import './Benefits.scss'

const Benefits = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    return (

        <div className='benefits' ref={innerRef}>

            {
                isSpanish ? (
                    <h2 className='title'>Beneficios</h2>
                ) : (
                    <h2 className='title'>Benefits</h2>
                )
            }

            <div className='items'>

                <div className='benefit-item'>

                    <img
                        src={dollar}
                        alt='dollar'
                        className='benefit-ico'
                    />

                    {
                        isSpanish ? (
                            <p>Ganarás más <b>dinero</b></p>
                        ) : (
                            <p>Earn more <b>money</b></p>
                        )
                    }

                </div>

                <div className='benefit-item'>

                    <img
                        src={clock}
                        alt='clock'
                        className='benefit-ico'
                    />

                    {
                        isSpanish ? (
                            <p>Ahorrarás más <b>tiempo</b></p>
                        ) : (
                            <p>Save <b>time</b></p>
                        )
                    }



                </div>

                <div className='benefit-item'>

                    <img
                        src={forward}
                        alt='forward'
                        className='benefit-ico'
                    />

                    {
                        isSpanish ? (
                            <p>Mejorarás tu <b>marca</b> personal</p>
                        ) : (
                            <p>Improve your personal <b>brand</b></p>
                        )
                    }



                </div>

                <div className='benefit-item'>

                    <img
                        src={handshake}
                        alt='handshake'
                        className='benefit-ico'
                    />

                    {
                        isSpanish ? (
                            <p>Utilizarás un <b>método único</b> en el mercado</p>
                        ) : (
                            <p>Deploy a <b>unique</b> approach in the market</p>
                        )
                    }



                </div>

                <div className='benefit-item'>

                    <img
                        src={security}
                        alt='security'
                        className='benefit-ico'
                    />

                    {
                        isSpanish ? (
                            <p>El <b>riesgo es 0</b></p>
                        ) : (
                            <p><b>Zero</b> risk</p>
                        )
                    }

                </div>

            </div>

        </div>

    );

}

export default Benefits;