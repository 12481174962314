import React, { useContext } from 'react'

import LanguageContext from '../../../context/language/LanguageContext'

import './Info.scss'

const Info = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    return (

        <div className='info' ref={innerRef}>

            <div className='item'>

                <h2 className='title'>
                    0 USD
                </h2>

                {
                    isSpanish ? (
                        <p className='text-white text-center'>No requiere inversión</p>
                    ) : (
                        <p className='text-white text-center'>No investment required</p>
                    )
                }

            </div>

            <div className='item'>

                <h2 className='title'>
                    +1M USD
                </h2>

                {
                    isSpanish ? (
                        <p className='text-white text-center'>Facturados por influencers con mi metodología</p>
                    ) : (
                        <p className='text-white text-center'>Influencer revenue using this methodology</p>
                    )
                }

            </div>

            <div className='item'>

                <h2 className='title'>
                    +200
                </h2>

                {
                    isSpanish ? (
                        <p className='text-white text-center'>Triggers mentales para lograr un correcto calentamiento</p>
                    ) : (
                        <p className='text-white text-center'>Mental triggers used to drive engagement</p>
                    )
                }

            </div>

        </div>

    )

}

export default Info;