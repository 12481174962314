import React, { useEffect, useContext } from 'react'

import equal from '../../../assets/maths/equal.png'
import plus from '../../../assets/maths/plus.png'

import LanguageContext from '../../../context/language/LanguageContext'

import Aos from 'aos'

import 'aos/dist/aos.css'

import './ThatIsNeeded.scss'

const ThatIsNeeded = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    useEffect(() => {
        Aos.init({
            duration: 1000, // values from 0 to 3000, with step 50ms
        })
    }, [])

    return (

        <div className='that-is-needed' ref={innerRef}>

            {
                isSpanish ? (
                    <h2 className='mt3'>
                        ¿Qué necesito?
                    </h2>
                ) : (
                    <h2 className='mt3'>
                        What do I need?
                    </h2>
                )
            }

            <div className='items'>

                <div data-aos='fade-top' className='item'>
                    {
                        isSpanish ? (
                            <>
                                <h3>Metodología</h3>
                                <p className='text-center'>Completamente <b>orgánica</b>, no requiere ads ni inversión</p>
                            </>
                        ) : (
                            <>
                                <h3>Methodology</h3>
                                <p className='text-center'>Completely <b>organic</b>, no ads or investment required.</p>
                            </>
                        )
                    }
                </div>

                <img
                    src={plus}
                    alt='plus'
                    className='ico-math'
                />

                <div data-aos='fade-top' className='item'>
                    {
                        isSpanish ? (
                            <>
                                <h3>Audiencia</h3>
                                <p className='text-center'><b>Views</b> en las historias y <b>conexión</b> con la audiencia</p>
                            </>
                        ) : (
                            <>
                                <h3>Audience</h3>
                                <p className='text-center'>Story views and an engaged <b>audience</b></p>
                            </>
                        )
                    }
                </div>

                <img
                    src={equal}
                    alt='equal'
                    className='ico-math'
                />

                <div data-aos='fade-top' className='item'>
                    {
                        isSpanish ? (
                            <>
                                <h3>Éxito</h3>
                                <p className='text-center'><b>Sold out</b> del producto/servicio ofrecido</p>
                            </>
                        ) : (
                            <>
                                <h3>Success</h3>
                                <p className='text-center'><b>Sell out</b> of product/service offered.</p>
                            </>
                        )
                    }
                </div>

            </div>

        </div>

    );

}

export default ThatIsNeeded;