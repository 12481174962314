export const getTitle = isSpanish => isSpanish ? 'Preguntas frencuentes' : 'FAQ´s'

export const getQuestions = isSpanish => isSpanish ? spanishQuestions : englishQuestions

const spanishQuestions = {
    q1: '¿Cuánto dura un lanzamiento?',
    a1: 'Aproximadamente un mes, pero eso puede variar dependiendo de cada caso',
    q2: '¿Necesito obligatoriamente un producto o servicio?',
    a2: 'En caso de que no tengas ningún infoproducto, juntos analizaremos como puedes aportarle valor a tu audiencia creando un producto/servicio desde cero.',
    q3: '¿Desgasto mi audiencia?',
    a3: 'Ya que la metodología es 100% orgánica, lo que te permitirá aumentar la interacción con tu audiencia aportando el mayor valor posible.',
}

const englishQuestions = {
    q1: 'How long does this process take from audience identification to product launch window?',
    a1: 'Approximately one month, but this may vary depending on individual needs.',
    q2: 'Do I need to have an existing product or service?',
    a2: "We can work with you to identify a product/service that brings value to your audience.",
    q3: 'Will this over-saturate my audience?',
    a3: 'Because the methodology is 100% organic, it’s based on increasing interaction with your audience through delivering the greatest possible value.',
}