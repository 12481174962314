export const getTitle = isSpanish => isSpanish ? '¿Cómo funciona?' : 'How does it work?'

export const getTitles = isSpanish => isSpanish ? spanishTitles : englishTitles

const spanishTitles = {
    personality:'Calentamiento de la personalidad',
    niche: 'Calentamiento del nicho',
    product: 'Calentamiento del producto',
    sale: 'Venta'
}

const englishTitles = {
    personality:'Building heat around your personality',
    niche: 'Driving buzz around what you do',
    product: 'Building excitement for your product',
    sale: 'Sales window'
}