import React, { useEffect, useContext } from 'react'

import celular from '../../../assets/celular.jpeg'

import LanguageContext from '../../../context/language/LanguageContext'

import Aos from 'aos'

import 'aos/dist/aos.css'

import './DidYouKnow.scss'

const DidYouKnow = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    useEffect(() => {
        Aos.init()
    }, [])

    return (

        <div className='did-you-know' ref={innerRef}>

            <img
                src={celular}
                data-aos='fade-bottom'
                className='celular-img'
                alt='celular'
            />

            <div data-aos='fade-top' className='texts'>

                {
                    isSpanish ? (
                        <>
                            <h2 className='title'>Sabías que...</h2>

                            <p className='text'>El <span className='blue-bold'>80%</span> de los influencers de Instagram <span className='blue-bold'>NO</span> monetiza su audiencia</p>
                        </>
                    ) : (
                        <>
                            <h2 className='title'>Did you know that...</h2>

                            <p className='text'><span className='blue-bold'>80%</span> of Instagram influencers do <span className='blue-bold'>NOT</span> monetize their audience.</p>
                        </>
                    )
                }

            </div>

        </div>

    )

}

export default DidYouKnow;