import React, { useContext } from 'react'

import item1 from '../../../assets/item-numbers/item-1.png'
import item2 from '../../../assets/item-numbers/item-2.png'
import item3 from '../../../assets/item-numbers/item-3.png'
import item4 from '../../../assets/item-numbers/item-4.png'

import LanguageContext from '../../../context/language/LanguageContext'

import { getTitle, getTitles } from './texts'

import './HowItWorks.scss'

const HowItWorks = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    const title = getTitle(isSpanish)
    const titles = getTitles(isSpanish)

    return (

        <div className='how-it-works' ref={innerRef}>

            <h2 className='title'>{title}</h2>

            <div className='items mt3'>

                <div className='item'>

                    <div className='div-title'>

                        <img
                            src={item1}
                            alt='item-1'
                            className='item-ico'
                        />

                        <h3>{titles.personality}</h3>

                    </div>

                    {
                        isSpanish ? (
                            <p className='text-center'>Conectamos con la <b>audiencia</b> y generamos <b>cercanía</b></p>
                        ) : (
                            <p className='text-center'>Driving engagement and closer <b>connection</b> with your <b>audience</b>. Getting to know your audience's needs</p>
                        )
                    }

                </div>

                <div className='item'>

                    <div className='div-title'>

                        <img
                            src={item2}
                            alt='item-2'
                            className='item-ico'
                        />

                        <h3>{titles.niche}</h3>

                    </div>
                    {
                        isSpanish ? (
                            <p className='text-center'>Explicamos a la perfección de que se trata el <b>rubro</b> en el que el <b>experto</b> se desarrolla</p>
                        ) : (
                            <p className='text-center'>Building <b>trust</b> and <b>authority</b> around your niche <b>expertise</b></p>
                        )
                    }

                </div>

                <div className='item'>

                    <div className='div-title'>

                        <img
                            src={item3}
                            alt='item-3'
                            className='item-ico'
                        />

                        <h3>{titles.product}</h3>

                    </div>

                    {
                        isSpanish ? (
                            <p className='text-center'>Detallamos de que se trata el <b>infoproducto</b> que vamos a <b>lanzar</b></p>
                        ) : (
                            <p className='text-center'>Differentiating and showing <b>exclusivity</b> of your <b>product</b></p>
                        )
                    }

                </div>

                <div className='item'>

                    <div className='div-title'>

                        <img
                            src={item4}
                            alt='item-4'
                            className='item-ico'
                        />

                        <h3>{titles.sale}</h3>

                    </div>

                    {
                        isSpanish ? (
                            <p className='text-center'>Aperturas de plazas para que la <b>audiencia</b> pueda <b>comprar</b></p>
                        ) : (
                            <p className='text-center'>Creating <b>urgency</b> around <b>opportunities</b> for audiences to <b>experience your product</b></p>
                        )
                    }

                </div>

            </div>

        </div>

    )

}

export default HowItWorks;