import React, { useRef } from 'react'
import Header from './components/header/Header';
import ThatIsNeeded from './components/that-is-needed/ThatIsNeeded';
import DidYouKnow from './components/did-you-know/DidYouKnow';
import HowItWorks from './components/how-it-works/HowItWorks';
import Info from './components/info/Info';
import BookYourCall from './components/book-your-call/BookYourCall';
import Navbar from './components/top-bar/Navbar';
import Footer from './components/footer/Footer';
import Benefits from './components/benefits/Benefits';
import QA from './components/qa/QA';

import LanguageState from '../context/language/LanguageState'

const App = () => {

	const refHeader = useRef(null)
	const refThatIsNeeded = useRef(null)
	const refDidYouKnow = useRef(null)
	const refHowItWorks = useRef(null)
	const refInfo = useRef(null)
	const refBookYourCall = useRef(null)
	const refBenefits = useRef(null)
	const refQA = useRef(null)

	return (

		<LanguageState>

			<div>

				<Navbar
					refThatIsNeeded={refThatIsNeeded}
					refDidYouKnow={refDidYouKnow}
					refHowItWorks={refHowItWorks}
					refInfo={refInfo}
					refBookYourCall={refBookYourCall}
					refBenefits={refBenefits}
					refQA={refQA}
				/>

				<Header innerRef={refHeader} />

				<HowItWorks innerRef={refHowItWorks} />

				<DidYouKnow innerRef={refDidYouKnow} />

				<Benefits innerRef={refBenefits} />

				<ThatIsNeeded innerRef={refThatIsNeeded} />

				<QA innerRef={refQA} />

				<Info innerRef={refInfo} />

				<BookYourCall innerRef={refBookYourCall} />

				<Footer />

			</div>

		</LanguageState>

	)

}
export default App;
