import {
    CHANGE_TO_SPANISH,
    CHANGE_TO_ENGLISH,
} from '../../types/index'
import languages from '../../types/language'

const LanguageReducer = (state, action) => {

    switch(action.type){

        case CHANGE_TO_SPANISH:
            return{
                ...state,
                language: languages.spanish,
                isSpanish: true,
            }

        case CHANGE_TO_ENGLISH:
            return{
                ...state,
                language: languages.english,
                isSpanish: false,
            }

        default:
            return state
    
    }

}

export default LanguageReducer