import React, { useContext } from 'react'

import LanguageContext from '../../../context/language/LanguageContext'

import whatsapp from '../../../assets/social-media/whatsapp.png'
import instagram from '../../../assets/social-media/instagram.png'

import './Footer.scss'

const Footer = () => {

    const { isSpanish } = useContext(LanguageContext)

    return (
        <footer className='footer'>

            <p>Tomás Jacob Copyright © 2023</p>

            <div className='social-media-div'>
                <div
                    className='social-media-button'
                    onClick={() => window.open('https://www.instagram.com/tomas_jacob/', '_blank', 'noreferrer')}
                >
                    <img
                        src={instagram}
                        alt='instagram'
                        className='social-media-ico'

                    />
                    <p>@tomas_jacob</p>
                </div>

                <div
                    className='social-media-button'
                    onClick={() => window.open(`https://wa.me/5491144900025?text=${isSpanish ? 'Hola Tomas! Quiero saber mas sobre los lanzamientos!' : 'Hi Tomas! I want to know more about the launches!'}`, '_blank', 'noreferrer')}
                >
                    <img
                        src={whatsapp}
                        alt='whatsapp'
                        className='social-media-ico'
                    />
                    <p>+54 9 11 4490-0025</p>
                </div>
            </div>

        </footer>
    );
}

export default Footer;