import React, { useState, useEffect, useContext } from 'react';

import menuIco from '../../../assets/icos/menu.png'

import closeIco from '../../../assets/icos/close.png'

import usIco from '../../../assets/flags/united-states.png'
import esIco from '../../../assets/flags/spain.png'

import './Navbar.scss';
import LanguageContext from '../../../context/language/LanguageContext';
import { itemsEnglish, itemsSpanish } from './texts';

function Navbar({
	refThatIsNeeded,
	refDidYouKnow,
	refHowItWorks,
	refInfo,
	refBookYourCall,
	refBenefits,
	refQA,
}) {

	const [click, setClick] = useState(false);

	const path = window.location.href

	const { changeToSpanish, changeToEnglish, isSpanish } = useContext(LanguageContext)

	const [showBackground, setShowBackground] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [path])

	useEffect(() => {
		window.addEventListener("scroll", () => {
		  if (window.scrollY > 200) {
			setShowBackground(true);
		  } else {
			setShowBackground(false);
		  }
		});
	  }, []);

	const handleClick = () => setClick(!click);

	const closeMobileMenu = () => setClick(false);
	
	const scrollTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: "smooth",
		});
	  };

	const itemTexts = isSpanish ? itemsSpanish : itemsEnglish

	return (

		<div className={`fondo-navbar ${click ? 'open-menu' : ''} ${showBackground ? 'back-blue' : ''}`}>

			<div className='title-desktop-div'>

				<div onClick={() => scrollTop()} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<h1 className='title-desktop'>Tomas Jacob</h1>
				</div>

				<ul className={click ? 'nav-menu active' : 'nav-menu'}>

					<Item label={itemTexts.howItWorks} closeMobileMenu={closeMobileMenu} elementRef={refHowItWorks} />

					<Item label={itemTexts.benefits} closeMobileMenu={closeMobileMenu} elementRef={refBenefits} />

					<Item label={itemTexts.whatINeed} closeMobileMenu={closeMobileMenu} elementRef={refThatIsNeeded} />

					<Item label={itemTexts.qa} closeMobileMenu={closeMobileMenu} elementRef={refQA} />

					<Item label={itemTexts.experience} closeMobileMenu={closeMobileMenu} elementRef={refInfo} />

					<Item label={itemTexts.bookYourCall} closeMobileMenu={closeMobileMenu} elementRef={refBookYourCall} />

					<div className='div-flags'>
						<img
							src={esIco}
							onClick={changeToSpanish}
							className='flag-ico'
							alt='spanish-flag'
						/>
						<img
							src={usIco}
							onClick={changeToEnglish}
							className='flag-ico'
							alt='usa-flag'
						/>
					</div>

				</ul>

			</div>

			<nav className={`navbar ${click ? 'open-menu' : ''}`}>

				<h1 className='title-mobile'>
					Tomas Jacob
				</h1>

				<div className='menu-icon' onClick={handleClick}>
					<img src={click ? closeIco : menuIco} alt='menu' className='menu-icon' />
					<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
				</div>

			</nav >

		</div>
	);
}

const Item = ({ label = '', closeMobileMenu = () => { }, elementRef }) => {
	return (
		<div
			className='div-item'
			onClick={() => {
				closeMobileMenu()
				window.scrollTo({
					top: elementRef?.current?.offsetTop - 70,
					behavior: "smooth",
				});
			}}
		>
			<p className='texto'>{label}</p>
		</div>
	)
}

export default Navbar;
