import React, { useContext } from 'react'

import LanguageContext from '../../../context/language/LanguageContext'

import './BookYourCall.scss'

const BookYourCall = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    return (

        <div className='book-your-call' ref={innerRef}>

            {
                isSpanish ? (
                    <>
                        <h2 className='text-white'>¿Listo para tomar acción y llevar tu facturación al siguiente nivel?</h2>

                        <p className='text-white  text-center'>Agenda una llamada gratis donde te explicaré más a fondo de qué consiste un lanzamiento por Instagram</p>

                        <button
                            onClick={() => window.open('https://calendly.com/tje-estudio/30min', '_blank', 'noreferrer')}
                        >
                            Reservá tu llamada
                        </button>
                    </>
                ) : (
                    <>
                        <h2 className='text-white'>Ready to take action and take your billing to the next level?</h2>

                        <p className='text-white  text-center'>Schedule a free call to hear in detail what an Instagram pitch is all about.</p>

                        <button
                            onClick={() => window.open('https://calendly.com/tje-estudio/30min', '_blank', 'noreferrer')}
                        >
                            Book your call
                        </button>
                    </>
                )
            }

        </div>

    )
}

export default BookYourCall;