import React, { useContext } from 'react'

import LanguageContext from '../../../context/language/LanguageContext'

import './Header.scss'

const Header = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    return (

        <div
            className='header'
            ref={innerRef}
        >

            <h2 className='text-white'>
                {isSpanish ? 'Lanzamiento del producto ideal' : 'Launching the ideal product'}
            </h2>


            {
                isSpanish ? (
                    <p className='text-white text-center'>Monetizá tu audiencia y multiplicá tus ingresos con una <b>estrategia exclusiva</b> de historias de Instagram.</p>
                ) : (
                    <p className='text-white text-center'>Monetize your audience and multiply your revenues with a <b>unique strategy of Instagram stories.</b></p>
                )
            }



        </div>

    );

}

export default Header;