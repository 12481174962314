import React, { useContext } from 'react'

import LanguageContext from '../../../context/language/LanguageContext'

import './QA.scss'
import { getQuestions, getTitle } from './texts'

const QA = ({ innerRef }) => {

    const { isSpanish } = useContext(LanguageContext)

    const title = getTitle(isSpanish)

    const questions = getQuestions(isSpanish)

    return (

        <div
            className='qa'
            ref={innerRef}
        >

            <div className='container'>

                <h2 className='title'>{title}</h2>

                <p className='mt3'>
                    <b>
                        {questions.q1}
                    </b>
                </p>
                <p>
                    {questions.a1}
                </p>

                <p className='mt3'>
                    <b>
                        {questions.q2}
                    </b>
                </p>
                <p>
                    <b>No!</b> {questions.a2}
                </p>

                <p className='mt3'>
                    <b>
                        {questions.q3}
                    </b>
                </p>
                <p>
                    <b>No!</b> {questions.a3}
                </p>

            </div>

        </div>

    )
}

export default QA;