export const itemsSpanish = {
    howItWorks: '¿Cómo funciona?',
    benefits: 'Beneficios',
    whatINeed: '¿Qué necesito?',
    experience: 'Experiencia',
    qa: 'FAQ´s',
    bookYourCall: 'Contactame',
}

export const itemsEnglish = {
    howItWorks: 'How does it work?',
    benefits: 'Benefits',
    whatINeed: 'What do I need?',
    experience: 'Experience',
    qa: 'FAQ´s',
    bookYourCall: 'Contact me',
}