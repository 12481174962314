import React, { useReducer } from 'react'

import LanguageContext from './LanguageContext'
import LanguageReducer from './LanguageReducer'

import { 
    CHANGE_TO_ENGLISH, 
    CHANGE_TO_SPANISH 
} from '../../types'

import languages from '../../types/language'

const LanguageState = props => {

    const initialState = {
        language: languages.spanish,
        isSpanish: true,
    }

    const [state, dispatch] = useReducer(LanguageReducer, initialState)

    const changeToSpanish = () => {
        dispatch({
            type: CHANGE_TO_SPANISH
        })
    }

    const changeToEnglish = () => {
        dispatch({
            type: CHANGE_TO_ENGLISH
        })
    }

    return (
        <LanguageContext.Provider
            value={{
                language: state.language,
                isSpanish: state.isSpanish,
                changeToSpanish,
                changeToEnglish,
            }}
        >
            {props.children}
        </LanguageContext.Provider>
    )

}


export default LanguageState